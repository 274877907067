// Default spacing values, 4px increments
// For use adding space between and within items,
// not for setting width or height on elements
$space-4: 4px;
$space-8: 8px;
$space-12: 12px;
$space-16: 16px;
$space-20: 20px;
$space-24: 24px;
$space-28: 28px;
$space-32: 32px;
$space-36: 36px;
$space-40: 40px;
$space-44: 44px;
$space-48: 48px;
$space-52: 52px;
$space-56: 56px;
$space-60: 60px;
$space-64: 64px;
$space-68: 68px;
$space-72: 72px;
$space-76: 76px;
$space-80: 80px;
$space-84: 84px;
$space-88: 88px;
$space-92: 92px;
$space-96: 96px;
$space-100: 100px;
$space-104: 104px;
$space-108: 108px;
$space-112: 112px;
$space-116: 116px;
$space-120: 120px;
$space-124: 124px;
$space-128: 128px;

// Responsive spacing values
// For use when browser text size should influence spacing
$space-responsive-4: 0.4rem;
$space-responsive-8: 0.8rem;
$space-responsive-12: 1.2rem;
$space-responsive-16: 1.6rem;
$space-responsive-20: 2rem;
$space-responsive-24: 2.4rem;
$space-responsive-28: 2.8rem;
$space-responsive-32: 3.2rem;
$space-responsive-36: 3.6rem;
$space-responsive-40: 4rem;
$space-responsive-44: 4.4rem;
$space-responsive-48: 4.8rem;
$space-responsive-52: 5.2rem;
$space-responsive-56: 5.6rem;
$space-responsive-60: 6rem;
$space-responsive-64: 6.4rem;
$space-responsive-68: 6.8rem;
$space-responsive-72: 7.2rem;
$space-responsive-76: 7.6rem;
$space-responsive-80: 8rem;
$space-responsive-84: 8.4rem;
$space-responsive-88: 8.8rem;
$space-responsive-92: 9.2rem;
$space-responsive-96: 9.6rem;
$space-responsive-100: 10rem;
$space-responsive-104: 10.4rem;
$space-responsive-108: 10.8rem;
$space-responsive-112: 11.2rem;
$space-responsive-116: 11.6rem;
$space-responsive-120: 12rem;
$space-responsive-124: 12.4rem;
$space-responsive-128: 12.8rem;
