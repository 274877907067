// Primary colors
@property --color-red {
  syntax: '<color>';
  inherits: false;
  initial-value: #d04a4d;
}
@property --color-pink {
  syntax: '<color>';
  inherits: false;
  initial-value: #edc9cd;
}
@property --color-yellow {
  syntax: '<color>';
  inherits: false;
  initial-value: #f7c544;
}
@property --color-light-beige {
  syntax: '<color>';
  inherits: false;
  initial-value: #ddd0c2;
}
@property --color-green {
  syntax: '<color>';
  inherits: false;
  initial-value: #50aa75;
}
@property --color-light-blue {
  syntax: '<color>';
  inherits: false;
  initial-value: #84bce5;
}
@property --color-gocomics-blue {
  syntax: '<color>';
  inherits: false;
  initial-value: #1a48b8;
}
@property --color-purple {
  syntax: '<color>';
  inherits: false;
  initial-value: #514e81;
}

// Secondary colors
@property --color-blue-100 {
  syntax: '<color>';
  inherits: false;
  initial-value: #edf0f3;
}
@property --color-blue-200 {
  syntax: '<color>';
  inherits: false;
  initial-value: #e7eff9;
}
@property --color-blue-300 {
  syntax: '<color>';
  inherits: false;
  initial-value: #e0e9ff;
}
@property --color-blue-400 {
  syntax: '<color>';
  inherits: false;
  initial-value: #a1baf9;
}
@property --color-blue-500 {
  syntax: '<color>';
  inherits: false;
  initial-value: #133a9a;
}
@property --color-blue-600 {
  syntax: '<color>';
  inherits: false;
  initial-value: #06287b;
}

// Grayscale colors
@property --color-white {
  syntax: '<color>';
  inherits: false;
  initial-value: #fff;
}
@property --color-gray-100 {
  syntax: '<color>';
  inherits: false;
  initial-value: #eee;
}
@property --color-gray-200 {
  syntax: '<color>';
  inherits: false;
  initial-value: #e3e3e3;
}
@property --color-gray-300 {
  syntax: '<color>';
  inherits: false;
  initial-value: #bbb;
}
@property --color-gray-400 {
  syntax: '<color>';
  inherits: false;
  initial-value: #888;
}
@property --color-gray-500 {
  syntax: '<color>';
  inherits: false;
  initial-value: #777;
}
@property --color-gray-600 {
  syntax: '<color>';
  inherits: false;
  initial-value: #585858;
}
@property --color-gray-700 {
  syntax: '<color>';
  inherits: false;
  initial-value: #313131;
}
@property --color-gray-800 {
  syntax: '<color>';
  inherits: false;
  initial-value: #242424;
}
@property --color-black {
  syntax: '<color>';
  inherits: false;
  initial-value: #000;
}

// Alert colors
@property --color-alert-error-100 {
  syntax: '<color>';
  inherits: false;
  initial-value: #faedea;
}
@property --color-alert-error-200 {
  syntax: '<color>';
  inherits: false;
  initial-value: #e6cbc3;
}
@property --color-alert-error-300 {
  syntax: '<color>';
  inherits: false;
  initial-value: #d9593a;
}
@property --color-alert-warning-100 {
  syntax: '<color>';
  inherits: false;
  initial-value: #fdf8eb;
}
@property --color-alert-warning-200 {
  syntax: '<color>';
  inherits: false;
  initial-value: #f1dfbd;
}
@property --color-alert-warning-300 {
  syntax: '<color>';
  inherits: false;
  initial-value: #e19836;
}
@property --color-alert-success-100 {
  syntax: '<color>';
  inherits: false;
  initial-value: #edf7ef;
}
@property --color-alert-success-200 {
  syntax: '<color>';
  inherits: false;
  initial-value: #c7e2cb;
}
@property --color-alert-success-300 {
  syntax: '<color>';
  inherits: false;
  initial-value: #64b969;
}
@property --color-alert-info-100 {
  syntax: '<color>';
  inherits: false;
  initial-value: #ccdff7;
}
@property --color-alert-info-200 {
  syntax: '<color>';
  inherits: false;
  initial-value: #b3cdee;
}
@property --color-alert-info-300 {
  syntax: '<color>';
  inherits: false;
  initial-value: #2c6ddc;
}
