// Decorative styles that can be applied to any element
$border-radius-5: 5px;
$border-radius-10: 10px;
$border-radius-15: 15px;
$border-radius-20: 20px;
$border-radius-25: 25px;
$border-radius-30: 30px;
$border-radius-40: 40px;
$border-radius-50: 50px;

// Elevations and usage notes are in the style guide
// Cards
$elevation-1: 0 3px 12px #00000038;

// Modals, popovers, sticky elements
$elevation-2: 0 3px 12px #0006;

// Button focus states
$elevation-3-gocomics-blue: 0 0 6px var(--color-gocomics-blue, #1a48b8);
$elevation-3-alert-error-300: 0 0 6px var(--color-alert-error-300, #d9593a);

// Buttons, dropdowns
$elevation-4: 0 3px 6px #00000029;

// Containers
$elevation-5: 0 0 6px #00000029;
