@import '/src/styles/utilities.scss';

.accountContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: var(--color-white);
}

.accountContent__list {
  padding: 0;
  margin: 0;
  list-style: none;

  & > * {
    border-bottom: 1px solid var(--color-gray-200);
  }
}

.accountContent__item {
  display: flex;
  align-items: center;
  height: 60px;

  &:hover {
    cursor: pointer;
    background-color: var(--color-gray-100);
  }
}

.accountContent__itemLink {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 $space-36;
}

.accountContent__followsItem {
  display: flex;
  align-items: center;
  height: 60px;

  &:hover {
    cursor: pointer;
    background-color: var(--color-gray-100);
  }
}

.accountContent__text {
  margin: 0;
  color: var(--color-text-dark);
}

.accountContent__authButtons {
  padding: $space-12 $space-36 $space-16;
}

.accountContent__signOut {
  @include undo-default-button-styles();

  width: 100%;
  height: 100%;
  padding: 0 $space-36;

  &:focus-visible {
    outline: 1px solid var(--color-gocomics-blue);
  }
}
