@mixin undo-default-button-styles {
  padding: 0;
  text-align: left;
  cursor: pointer;
  background-color: transparent;
  border: $button-border-width solid transparent;

  &:focus {
    outline: 0;
  }
}

// Effects for Buttons defined as "Primary" in the style guide and it's variants
@mixin primary-filled-effects {
  color: var(--color-white);
  background-color: var(--color-gocomics-blue);
  border: $button-border-width solid var(--color-gocomics-blue);
  border-radius: $border-radius-50;

  &:disabled,
  &[aria-disabled='true'] {
    color: var(--color-blue-400);
    cursor: unset;
    background-color: var(--color-blue-300);
    border: $button-border-width solid var(--color-blue-300);
  }

  &:focus-visible {
    background-color: var(--color-blue-500);
    border: $button-border-width solid var(--color-blue-500);
    outline: 0;
    box-shadow: 0 0 3px 1px var(--color-gocomics-blue);
  }

  &:hover:not(:disabled, [aria-disabled='true']) {
    color: var(--color-white);
    background-color: var(--color-blue-500);
    border: $button-border-width solid var(--color-blue-500);
  }

  &:active:not(:disabled, [aria-disabled='true']) {
    background-color: var(--color-blue-600);
    box-shadow: $button-shadow-inner;
  }
}

@mixin danger-filled-effects {
  color: var(--color-white);
  background-color: var(--color-alert-error-300);
  border: $button-border-width solid var(--color-alert-error-300);
  border-radius: $border-radius-50;

  &:disabled,
  &[aria-disabled='true'] {
    cursor: unset;
    background-color: var(--color-alert-error-200);
    border: $button-border-width solid var(--color-alert-error-200);
  }

  &:focus-visible {
    background-color: var(--color-alert-error-300);
    border: $button-border-width solid var(--color-alert-error-300);
    outline: 0;
    box-shadow: 0 0 3px 1px var(--color-alert-error-300);
  }

  &:hover:not(:disabled, [aria-disabled='true']) {
    background-color: color-darken(var(--color-alert-error-300), 15%);
    border: $button-border-width solid
      color-darken(var(--color-alert-error-300), 15%);
  }

  &:active:not(:disabled, [aria-disabled='true']) {
    background-color: var(--color-alert-error-300);
    box-shadow: $button-shadow-inner;
  }
}

@mixin primary-stroked-effects($background-color-base: var(--color-white)) {
  color: var(--color-gocomics-blue);
  background-color: $background-color-base;
  border: $button-border-width solid var(--color-gocomics-blue);
  border-radius: $border-radius-50;

  &:disabled,
  &[aria-disabled='true'] {
    color: var(--color-blue-400);
    cursor: unset;
    border: $button-border-width solid var(--color-blue-300);
  }

  &:focus-visible {
    border: $button-border-width solid var(--color-gocomics-blue);
    outline: 0;
    box-shadow: 0 0 3px 1px var(--color-gocomics-blue);
  }

  &:hover:not(:disabled, [aria-disabled='true']) {
    color: var(--color-gocomics-blue);
    box-shadow: inset 0 0 0 2px var(--color-gocomics-blue);
  }

  &:active:not(:disabled, [aria-disabled='true']) {
    color: var(--color-white);
    background-color: var(--color-gocomics-blue);
    box-shadow: $button-shadow-inner;
  }
}

// Effects for Buttons defined as "Text Button" in the style guide and it's variants
@mixin text-button-effects {
  font-family: $font-primary;
  font-weight: $font-weight-bold;
  color: var(--color-gocomics-blue);
  text-decoration: none;

  &:disabled,
  &[aria-disabled='true'] {
    color: var(--color-blue-400);
    cursor: unset;
  }

  &:hover:not(:disabled, [aria-disabled='true']) {
    color: var(--color-alert-info-300);
  }

  &:active:not(:disabled, [aria-disabled='true']) {
    color: var(--color-alert-info-300);
  }
}

// TODO: discuss, do we need a component for this?
@mixin text-button-small-effects($base-color: 'color-gocomics-blue') {
  $color-base: var(--color-gocomics-blue);
  $color-interact: var(--color-alert-info-300);
  $color-disabled: var(--color-alert-info-200);

  @if $base-color == 'color-white' {
    $color-base: var(--color-white);
    $color-interact: var(--color-white);
    $color-disabled: $color-disabled;
  }

  @if $base-color == 'color-error' {
    $color-base: var(--color-alert-error-300);
    $color-interact: color-darken(var(--color-alert-error-300), 15%);
    $color-disabled: $color-disabled;
  }

  font-family: $font-primary;
  font-size: 1.4rem;
  font-weight: $font-weight-regular;
  color: $color-base;

  &:disabled,
  &[aria-disabled='true'] {
    color: $color-disabled;
    cursor: unset;
  }

  &:hover:not(:disabled, [aria-disabled='true']) {
    color: $color-interact;
    text-decoration: underline;
  }

  &:active:not(:disabled, [aria-disabled='true']) {
    color: $color-interact;
  }
}

// Effects for ToolBar Buttons
@mixin toolbar-button-effects {
  padding: $space-12 $space-16;
  color: var(--color-gocomics-blue);
  background-color: var(--color-gray-100);
  border: $button-border-width solid var(--color-gray-100);
  border-radius: $border-radius-50;

  &:disabled {
    cursor: unset;
    opacity: 0.4;
  }

  &:hover:not(:disabled, [aria-disabled='true']) {
    cursor: pointer;
    background-color: var(--color-gray-200);
  }

  &:active:not(:disabled, [aria-disabled='true']) {
    background-color: var(--color-gray-300);
    box-shadow: 0 0 6px var(--color-gocomics-blue);
  }
}

// Effects for Buttons defined as "Secondary" in the style guide and it's variants
@mixin floating-action-effects {
  color: var(--color-gocomics-blue);
  background-color: var(--color-white);
  border-radius: 28px;
  box-shadow: $button-shadow-outer;

  &:disabled,
  &[aria-disabled='true'] {
    color: var(--color-blue-400);
    cursor: unset;
    background-color: var(--color-gray-200);
    box-shadow: none;
  }

  &:focus-visible {
    background-color: var(--color-blue-500);
    border: $button-border-width solid var(--color-blue-500);
    outline: 0;
    box-shadow: 0 0 3px 1px var(--color-gocomics-blue);
  }

  &:hover:not(:disabled, [aria-disabled='true']) {
    background-color: var(--color-gray-100);
  }

  &:active:not(:disabled, [aria-disabled='true']) {
    background-color: var(--color-gray-100);
    box-shadow: $button-shadow-outer, $button-shadow-inner;
  }
}

// Effects for icon buttons used in puzzle nav
@mixin icon-button-effects {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  border: $button-border-width solid var(--color-white);
  border-radius: $border-radius-50;

  &:disabled {
    cursor: unset;
    opacity: 0.4;
  }

  &:hover:not(:disabled) {
    background-color: var(--color-gray-600);
  }

  &:active:not(:disabled),
  &:focus:not(:disabled) {
    background-color: var(--color-gray-700);
    box-shadow: 0 0 12px rgb(255 255 255 / 40%);
  }
}

// Misc
@mixin with-icon {
  display: flex;
  gap: $space-12;
  align-items: center;
  align-self: center;
  justify-content: center;
}
