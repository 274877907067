@import '/src/styles/utilities.scss';

.dropdownLink {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 $space-44;
  color: var(--color-text-dark);

  &:hover,
  &:focus {
    color: var(--color-text-dark);
  }

  /* Make sure Typography inherits these styles */
  & > h3 {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.dropdownLink__image {
  margin-right: $space-8;
  margin-left: $space-4;
  object-fit: contain;
  border-radius: $border-radius-15;
}
